<template>
    <b-modal id="modalCorpus"
             ref="modalCorpus"
             centered
             no-close-on-backdrop
             no-close-on-esc
             hide-header-close
             header-text-variant="light"
             v-model="modalCorpusShow"
             :title="$t('corpusModalTitle')">

        <p>{{$t('corpusModalContent')}}</p>

        <b-form-select v-model="corpus" ref="corpusInput" :options="corpusList"></b-form-select>

        <div slot="modal-footer" class="modal-footer-custom" style="justify-content: end">
            <b-button class="float-left btn-danger" @click="$root.$emit('hideCorpusModal')">{{$t('cancel')}}</b-button>
            <b-button class="float-right ml-2" variant="success" @click="handleOk">{{$t('save')}}</b-button>
        </div>

    </b-modal>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"

    export default {
        name: "ModalCorpus",
        data () {
            return {
                modalCorpusShow: true,
                corpus: localStorage.getItem("IEC_corpus") || "Grocery",
                inputState: null,
                corpusList: [
                    {
                        text: "Grocery",
                        value: "Grocery"
                    },
                    {
                        text: "Telecom",
                        value: "Telecom"
                    },
                    {
                        text: "High Tech",
                        value: "High_Tech"
                    },
                    {
                        text: "Furniture",
                        value: "Furniture"
                    },
                    {
                        text: "Fashion",
                        value: "Fashion"
                    },
                    {
                        text: "Beauty",
                        value: "Beauty"
                    },
                    {
                        text: "Luxury",
                        value: "Luxury"
                    },
                    {
                        text: "DIY",
                        value: "DIY"
                    },
                    {
                        text: "Hospitality",
                        value: "Hospitality"
                    },
                    {
                        text: "Default",
                        value: "Weshop"
                    },
                ]
            }
        },
        methods: {
            handleOk(){
                if(isNotNull(this.corpus)){
                    localStorage.setItem("IEC_corpus", this.corpus)
                    this.$root.$emit('hideCorpusModal')
                }else{
                    this.inputState = false
                }
            }
        }
    }
</script>
