<template>
    <div id="food" class="univers">
        <NewUpdateAvailable v-if="newUpdateAvailable" />
        <Header v-if="pageInfos != null" :nbProductsCart="nbProductsCart" :title="pageInfos.title" :description="pageInfos.description" :shopName="pageInfos.shopName" :titleAtRight="pageInfos.titleAtRight" :showBasket="pageInfos.showBasket != false" :showSearch="pageInfos.showSearch" :showScan="pageInfos.showScan" :totalAmount="basket.totalAmount || 0" :isOffline="isOffline" :backButton="pageInfos.showBackBtn" :filterButton="pageInfos.showFilterBtn" @headerHeight="headerHeight = $event" @showScanner="handleShowScan()" @showSearchInput="showSearchInput = $event"></Header>
        <router-view  :showSearchInput="showSearchInput" :titleToSearch="titleToSearch" :style="{paddingTop: `${headerHeight + 16}px`}" :key="$route.fullPath + refreshKey" :isOffline="isOffline" @pageCreated="handlePageCreated($event)" ></router-view>
        <ModalIdentification v-if="modalShow"></ModalIdentification>
        <Loader :content="$t('loading')" v-if="showLoader"></Loader>
        <ScannerModal :show="showScanModal" @updateShow="showScanModal = $event"></ScannerModal>
        <Alert ref="Alert" :alertContent="alertContent" :alertVariant="alertVariant"></Alert>
        <ModalLockerLocation v-if="modalLockerLocationShow"></ModalLockerLocation>
        <ModalCorpus v-if="modalCorpusShow"></ModalCorpus>
    </div>
</template>

<script>
    import {getProductsById} from "@/assets/js/Catalog"
    import "@/assets/css/custom-food.css"
    import Header from "@/components/Food/Header";
    import ModalIdentification from "@/components/Food/ModalIdentification";
    import ScannerModal from "@/components/Food/ScannerModal";
    import Loader from "@/components/Common/Loader";
    import Alert from "@/components/Common/Alert";
    import {isNotNull, getConfig} from "@/assets/js/Utils"
    import ModalCabin from "@/components/Food/ModalCabin";
    import ModalLockerLocation from "./ModalLockerLocation";
    import ModalCorpus from "./ModalCorpus";
    var Basket = require("@/assets/js/Food/Basket");
    var qrcodeGenerator = require('qrcode-generator')
    var App = require("@/assets/js/App")
    var Utils = require("@/assets/js/Utils")
    var Customer = require("@/assets/js/Customer")
    let idb = require("idb")
    let notificationIcon = require("@/assets/img/wl-symbol-logo.png")

    export default {
        name: "Food",
        components: {ModalLockerLocation, ModalCorpus, ModalCabin, Header,ModalIdentification,Loader,Alert, ScannerModal},
        data: function() {
            return{
                basket: {
                    "id": 0,
                    "totalAmount": 0,
                    "basketProducts": [],
                    "lockedProducts" :[]
                },
                nbProductsCart : 0,
                qrCodeImg :'',
                showLoader: false,
                modalShow: false,
                base: "",
                productCrossSell: null,
                alertContent: "",
                alertVariant: "",
                logoURL: "",
                customer: null,
                modalCabinShow: false,
                isOffline: sessionStorage.getItem("DKC_is_offline") == "true",
                keyword: null,
                showModalStore: false,
                newUpdateAvailable: false,
                pageInfos: null,
                headerHeight: 150,
                titleToSearch: false,
                showSearchInput: false,
                mqttSubscribed: false,
                refreshKey: 0,
                showScanModal: false,
                modalLockerLocationShow: false,
                modalCorpusShow: false,
            }
        },
        created(){
            let i18n = {};
            Object.keys(this.langs).forEach( lang => {
                    i18n[lang] = require('@/locales/'+lang+'.json');
                    this.$root.$i18n.setLocaleMessage(lang,i18n[lang].food)
                }
            );

            if(isNotNull(localStorage.getItem("IEC_lang"))){
                this.$root.$i18n.locale = localStorage.getItem("IEC_lang");
            }else{
                this.$root.$i18n.locale = 'en';
                localStorage.setItem("IEC_lang", this.$root.$i18n.locale)
            }

            let token = localStorage.getItem("IEC_TOKEN");
            document.dispatchEvent(new Event('x-app-rendered'))

            // if(!this.$route.query.inBuild){
            // //   if(!token){
            // //     setTimeout(() => {
            // //       this.modalShow = true
            // //     }, 500);
            // //   }else{
            // //     this.modalShow = false
            // //     localStorage.setItem("IEC_TOKEN", token)
            // //     localStorage.setItem("IEC_FIRSTNAME", Cookies.get("IEC_FIRSTNAME"))
            // //     localStorage.setItem("IEC_LASTNAME", Cookies.get("IEC_LASTNAME"))
            // //     localStorage.setItem("IEC_EMAIL", Cookies.get("IEC_EMAIL"))
            // //   }
            // }

            if(!this.$route.query.inBuild){
                if(!token){
                Customer.registerCustomer({}, (data) => {
                        if(data.error){
                            this.error = true
                            this.errorMessage = data.message || null
                            this.$parent.showLoader = false
                        }else{
                            window.location.replace(window.location.origin)
                            this.$parent.showLoader = false;
                        }
                    })
                }
            }





            App.saveDeviceType();

            this.refreshCustomer();
            document.addEventListener('newContentAvailable', (e) => {
                this.newUpdateAvailable = true;
            }, false);
            this.basket = Basket.getBasket();

            if(isNotNull(localStorage.getItem("DKC_productCrossSell"))){
                this.productCrossSell = JSON.parse(localStorage.getItem("DKC_productCrossSell"));
            }

            window.onunload = function(){
                this.closeHandler();
            }
            let mqttTopic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.SEND_SUBSTATE
            this.$mqtt.initMqttClient(() => {
                this.$mqtt.subscribeMqtt(mqttTopic, () => {
                    this.mqttSubscribed = true
                    this.$mqtt.listenMqttMessage(mqttTopic, message => {
                        let ticket = JSON.parse(localStorage.getItem("IEC_ticket"));
                        let decodedMessage = JSON.parse(new TextDecoder().decode(message))
                        if(decodedMessage && decodedMessage.basketComplexId && decodedMessage.basketComplexId == ticket.complexId){

                            let notifTitle = "Click&Collect";
                            let orderState = this.$t("orderConfirmed")
                            let sendNotification = true
                            switch(decodedMessage.subState){
                                case "IN_PREPARATION":
                                    localStorage.setItem("IEC_SUBSTATE", decodedMessage.subState)
                                    orderState = this.$t("orderInPreparation")
                                    break;
                                case "PREPARED":
                                    localStorage.setItem("IEC_SUBSTATE", decodedMessage.subState)
                                    orderState = this.$t("orderReady")
                                    break;
                                case "FINISH":
                                    sendNotification = false
                                    localStorage.removeItem("IEC_SUBSTATE")
                                    break;
                                case "NEW":
                                    localStorage.removeItem("IEC_SUBSTATE")
                                    break;
                            }
                            if(sendNotification){
                                try{
                                    let notifBody = this.$t("notificationOrderTitle", { orderNumber: ticket.orderNumber, orderState: orderState.toLowerCase() }) +'.';
                                    let options = {
                                        body: notifBody,
                                        badge: notificationIcon,
                                        icon: notificationIcon,
                                        renotify: true,
                                        tag: `notification-${ticket.orderNumber}`,
                                        data:{
                                            urlToOpen: window.location.origin + "/ticket?ticketId=" + ticket.complexId
                                        }
                                    }
                                    this.showNotification(notifTitle, options)
                                }catch(e){
                                    console.log(e)
                                }
                            }
                        }
                    })
                })
            })

            this.$root.$on("showLockerLocationModal", () => {
                this.modalLockerLocationShow = true
            })
            this.$root.$on("hideLockerLocationModal", () => {
                this.modalLockerLocationShow = false
            })

            this.$root.$on("showCorpusModal", () => {
                this.modalCorpusShow = true
            })
            this.$root.$on("hideCorpusModal", () => {
                this.modalCorpusShow = false
            })
        },
        mounted(){
            Utils.setAppContext(this);

            window.addEventListener("offline", envent => {
                this.isOffline = true
                sessionStorage.setItem("DKC_is_offline", true);
            })

            window.addEventListener("online", envent => {
                this.isOffline = false
                sessionStorage.setItem("DKC_is_offline", false)
            })
            this.basket = Basket.getBasket();
            this.$root.$on("basketChgt", () => {
                this.basketChange()
            })
        },
        methods: {
            basketChange: function(){
                this.basket = Basket.getBasket();
                this.count(this.basket.basketProducts);
                if(this.$route.name == "Basket"){
                    this.refreshKey++
                }
            },
            count: function(products) {
                var count = 0;
                products.forEach(function(product) {
                    count+=product.qty;
                });
                this.nbProductsCart = count;
            },
            share: function(){
                var qr = qrcodeGenerator(0, 'L');
                qr.addData(window.location.origin + localStorage.getItem("DKC_base"));
                qr.make();
                this.qrCodeImg = qr.createDataURL(10, 0);
                this.$refs.shareModal.show();
            },
            showAlert(content, type = ""){
                var variant = "";
                switch (type) {
                    case 'success':
                        variant = "success";
                        break;
                    case 'error':
                        variant = "danger";
                        break;
                    case 'info':
                        variant = "info";
                        break;
                    default:
                        variant = "info";
                }
                this.alertContent = content;
                this.alertVariant = variant;
            },
            checkDemonstrationLogo(){
                var path = Utils.getConfig().PATH_IMG + localStorage.getItem("DKC_demoId");
                this.logoURL = Utils.getConfig().DKW_URL + path;
            },
            handleShowScan(){
                this.showScanModal = true
            },
            refreshCustomer(){
                var self = this;
                var token = localStorage.getItem("DKC_food_token");
                if(isNotNull(token)){
                    Customer.getCustomer(function(jsonCustomer){
                        if(isNotNull(jsonCustomer)){
                            self.customer = JSON.parse(jsonCustomer);
                        }
                    });
                }
            },
            getAllProducts(){
                var path = "/catalog/product/getAll";
                var url = getConfig().DKW_URL + path;
                var request = new XMLHttpRequest();
                request.onreadystatechange = () => {
                    if (request.readyState === XMLHttpRequest.DONE) {
                        if (request.status === 200 && request.response != "") {
                            this.saveAllProductsLocaly(JSON.parse(request.response));
                        }else{
                            getConfig().appContext.showAlert(getConfig().appContext.$t('errorGetCustomer'),"error");
                        }
                    }
                };
                request.open('GET', url, true);
                request.send();
            },
            saveAllProductsLocaly(products){
                if (!('indexedDB' in window)) {return null;}
                const db = idb.openDB('demokit-food', 1, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains('products')) {
                            db.createObjectStore('products', {keyPath: 'id'});
                        }
                    }
                })
                .then(db => {
                    const tx = db.transaction('products', 'readwrite');
                    Promise.all(products.map(product => tx.store.put(product)))
                    return tx.complete
                })
            },
            handlePageCreated(event){
                this.pageInfos = JSON.parse(JSON.stringify(event))
            }
    },
    watch:{
            basket: function(newVal, oldVal) {
                //todo ?
                localStorage.getItem("DKC_basket");
                this.count(this.basket.basketProducts);
                this.$emit('chgtQty', this.nbrProducts);
                console.log(newVal)
            },
            showSearchInput(newVal, oldVal){
                if(newVal){
                    this.pageInfos.title = this.$t("pages.search.title")
                    this.pageInfos.description = null
                    this.pageInfos.showBackBtn = true
                }
            }
        }
    }

</script>
<style scoped>
.page-body{
    padding-top: 150px;
}
</style>
