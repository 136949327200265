<template>
    <div ref="header" class="food-header">
        <header class="" v-if="!$route.query.needParameters">
            <section class="global-actions">
                <div @click="showSidebar = true" :style="{backgroundImage: `url('${require('@/assets/img/menu-icon.png')}')`}" class="brand-logo"></div>
                <div v-if="showSearch" class="search">
                    <input type="text" v-if="showSearchInput" class="search-input" disabled v-model="searchKeyword">
                    <span class="icon-search icon" @click="updateSearchInput()"></span>
                </div>
                <div v-if="showScan" class="scan-picto" @click="showScanner()">
                    <span class="icon-scan icon"></span>
                </div>
                <div v-if="showBasket" :class="{'header-basket': true, 'to-right': !showSearch}" @click="$router.push(`/basket`)">
                    <template v-if="!showStep">
                        <div class="icon-wrapper">
                            <span class="icon-panier"></span>
                            <span :class="{'nbr-products': true, 'shake': animateCart}">{{nbProductsCart}}</span>
                        </div>
                        <span class="total-amount">{{fromattedTotalAmount}}€</span>
                    </template>
                    <template v-else>
                        <span class="header-step">{{stepToShow}}</span>
                    </template>
                </div>
                <h3 class="title-at-right" v-if="!showBasket && titleAtRight">{{title}} </h3>
            </section>
            <section class="page-actions">
                <span v-if="backButton" class="icon-fleche-gauche back-chevron" @click="$router.go(-1)"></span>
                <div class="page-info">
                    <h3 v-if="title != null && !titleAtRight">{{title}} </h3>
                    <p v-if="description != null">{{description}}</p>
                    <div v-if="shopName != null" class="d-flex mt-2">
                        <span class="icon-magasin"></span>
                        <h5 class="ma-0 ml-2" >{{shopName}}</h5>
                    </div>
                </div>
                <span v-if="filterButton" class="icon-filter filter-icon"></span>
            </section>
        </header>
        <SideBar v-if="showSidebar" @hideSidebar="showSidebar = false" :logoUrl="logoUrl"/>
        <nav class="header-menu">
            <b-collapse id="collapse-4">
                <div @click="goSchedule()" class="menu-element">
                    <i class="mdi mdi-store elment-icon"></i>
                    <i class="elment-text">{{$t("storeOpeningHours")}}</i>
                </div>
            </b-collapse>
            <b-collapse id="collapse-offline">
                <div class="offline-bar">
                    {{$t("offlineText")}}
                </div>
            </b-collapse>
        </nav>
    </div>
</template>

<script>
import SideBar from "@/components/Food/SideBar.vue"
    export default {
        name : 'Header',
        components:{SideBar},
        props : {
            nbProductsCart: {
                type: Number
            },
            isOffline: {
                type: Boolean
            },
            totalAmount: {
                type: Number
            },
            backButton:{
                type: Boolean,
                default: false
            },
            filterButton: {
                type: Boolean,
                default: false
            },
            title:{
                type: String,
            },
            description: {
                type: String
            },
            showBasket: {
                type: Boolean,
                default: true
            },
            showSearch: {
                type: Boolean,
                default: true
            },
            showScan: {
                type: Boolean,
                default: true
            },
            titleAtRight: {
                type: Boolean,
                default: false
            },
            shopName: {
                type: String
            }
        },
        data(){
            return{
                base: localStorage.getItem("DKC_base"),
                showMenu: false,
                offlineBar: true,
                animateCart: false,
                showSidebar: false,
                expandHours: false,
                searchKeyword: this.$t("searchNotImplemented"),
                showSearchInput: false
            }
        },
        methods: {
            redirect: function(){
                this.$parent.closeHandler();
                this.$router.push(localStorage.getItem("DKC_base"));
            },
            goBack(){
                if(this.$route.name === "FoodPaymentDone" || this.$route.name === "FoodPaymentTicket" ){
                    this.$router.push({name: "FoodIndex"})
                }else{
                    this.$router.go(-1)
                }
            },
            goSchedule(){
                this.$router.push(localStorage.getItem("DKC_base") + '/schedule');
            },
            updateSearchInput(){
                this.showSearchInput = !this.showSearchInput
                this.$emit("showSearchInput", this.showSearchInput)
            },
            showScanner(){
                this.$emit("showScanner", true)
            }
        },
        created(){
            this.logoUrl = require("@/assets/img/wl-horizontal-logo.png")
        },
        mounted(){
            this.$emit("headerHeight", this.$refs.header.offsetHeight)
        },
        computed:{
            iconToShow(){
                if(this.$route.name === "FoodIndex" || this.$route.name === "FoodScan"){
                    return this.showMenu === true ? 'close' : 'menu'
                }
                return 'chevron'
            },
            fromattedTotalAmount(){
                return this.totalAmount.toFixed(2)
            },
            showStep(){
                return (this.$route.name === "FoodPaymentMethods" || this.$route.name === "FoodBasket")
            },
            stepToShow(){
                switch(this.$route.name){
                    case "FoodBasket":
                        return this.$t("basketStep")
                    case "FoodPaymentMethods":
                        return this.$t("paymentStep")

                }
            },
            enableOfflineBar(){
                return (this.$route.name === "FoodScan" || this.$route.name === "FoodIndex") && this.isOffline
            }
        },
        watch:{
            nbProductsCart: function() {
                this.animateCart = true
                setTimeout(() => this.animateCart = false, 1000)
            },
            enableOfflineBar(){
                this.$root.$emit('bv::toggle::collapse', 'collapse-offline')
            },
            title(){
                setTimeout(() => {this.$emit("headerHeight", this.$refs.header.offsetHeight)}, 50)
                
            }
        }
    }
</script>

<style scoped>
    .page-actions{
        color: white;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 16px;
        margin: 16px 0 0 0;
    }
    .page-info{
        margin: 0 8px;
    }
    .page-info h3{
        font-weight: bold;
        margin: 0;
    }
    .page-info p{
        margin: 0;
        font-size: 0.8rem
    }
    /* .page-info h5{
        text-align: center;
        margin: 10px 35px 0 20px;
        padding: 5px 5px 5px 5px;
        border: solid;
        border-radius: 30px;
        font-size: 1rem
    } */
    .page-actions span{
        font-size: 1.6rem;
    }
    .page-actions .filter-icon{
        margin-left: auto;
    }
    .basket-body{
        margin-top: 150px;
        overflow: scroll;
    }
    .title-at-right{
        font-weight: bold;
        margin: 0;
        color: white;
        margin-left: auto;
        padding-right: 16px;
    }
    .search, .scan-picto{
        display: flex;
        background: white;
        align-items: center;
        border-radius: 45px;
        margin-left: auto;
        margin-right: 5px;
        max-width: 50%;
    }
    .scan-picto{
        margin-left: 0;
    }
    .search .icon, .scan-picto .icon{
        padding: 10px;
    }
    .search .search-input{
        border: none;
        font-size: 0.85rem;
        border-radius: 25px;
        width: 90%;
    }
    .search .search-input:active, .search .search-input:focus{
        border: none;
        outline: none;
    }
    .search .search-input.hide-search{
        display: none;
    }
</style>
