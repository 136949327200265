<template>
    <div class="sidebar-wrapper" @click.self="$emit('hideSidebar')">
        <div class="sidebar py-2">
            <div class="sidebar-header" @click="$emit('hideSidebar')">
                <span @click="$emit('sidebarStateUpdated', true)" class="icon-fleche-gauche"></span>
                <div class="sidebar-logo" :style="{backgroundImage: `url('${logoUrl}')`}"></div>
                <b-dropdown  variant="link"  toggle-class="text-decoration-none" class="menu" no-caret>
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" style="color: #46b8a6;"></b-icon>
                    </template>
                    <b-dropdown-item @click="clearCache()">{{$t("clear-cache")}}</b-dropdown-item>
                    <b-dropdown-item @click="$root.$emit('showLockerLocationModal')">{{$t("lockerLocationModal")}}</b-dropdown-item>
                    <b-dropdown-item @click="$root.$emit('showCorpusModal')">{{$t("corpusModal")}}</b-dropdown-item>
                    <b-dropdown-item @click="openLocker(1)">{{$t("openLocker1")}}</b-dropdown-item>
                    <b-dropdown-item @click="openLocker(2)">{{$t("openLocker2")}}</b-dropdown-item>
                </b-dropdown>
                
            </div>
            <div class="divider px-3"></div>
            <div class="sidebar-scroll">
                <div class="sidebar-content px-3">
                    <div @click="expandHours = !expandHours" class="sidebar-content-header">
                        <span class="icon-magasin"></span>
                        <span class="content-title">{{$t("storeInfo")}}</span>
                        <span :class="{'icon-fleche-up': expandHours, 'icon-fleche-down' : !expandHours}"></span>
                    </div>
                    <b-collapse id="expand-hours" v-model="expandHours" class="mt-2">
                        <h5 class="shopName" >{{$t("pages.home.shopName")}}</h5>
                        <small class="changeLangButton btnDisable">({{$t("pages.home.changeShopName")}})</small>
                        <div class="sidebar-content-date">
                            <span class="days">{{$t("mondayToSaturday")}}</span>
                            <span class="hours">08:00am - 08:00pm</span>
                        </div>
                        <div class="sidebar-content-date">
                            <span class="days">{{$t("sunday")}}</span>
                            <span class="hours">09:00am - 08:00pm</span>
                        </div>
                    </b-collapse>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div class="sidebar-content-header" @click="changeRoute('/')">
                        <span class="icon-home sidebar-elmt-icon"></span>
                        <span class="content-title">{{$t("homeSidebarLabel")}}</span>
                    </div>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div class="sidebar-content-header" @click="changeRoute('/orders')">
                        <span class="icon-orders sidebar-elmt-icon"></span>
                        <span class="content-title">{{$t("ordersHistorySidebarLabel")}}</span>
                    </div>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div @click="expandFav = !expandFav" class="sidebar-content-header">
                        <b-icon icon="suit-heart"></b-icon>
                        <span class="content-title">{{$t("FavoriteSidebarLabel")}}</span>
                        <span :class="{'icon-fleche-up': expandFav, 'icon-fleche-down' : !expandFav}"></span>
                    </div>
                    <b-collapse id="expand-hours" v-model="expandFav" class="mt-2">

                        <div class="participant" v-for="(favoriteProduct, id) in favoriteProducts" :key="id"
                            @click="handleCheckBoxClick(favoriteProduct)">
                            <div class="product-img" style="width: 40px;height: 40px;"
                                :style="{backgroundImage: `url('${productImg(favoriteProduct)}')`}"></div>
                            <span class="name" style="font-size:12px">{{translateName(favoriteProduct.name)}}</span>
                            <span class="mx-1 basket-total"
                                style="font-size:10px">({{formatPrice(favoriteProduct.price)}}€)</span>
                            <div class="ml-auto custom-radio-wrapper">
                                <span
                                    :class="{'custom-radio': true, 'checked':selectedFavoriteProduct.includes(favoriteProduct.id) }"></span>
                                <input type="checkbox">
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div class="sidebar-content-header">
                        <span class="icon-aide sidebar-elmt-icon"></span>
                        <span class="content-title">{{$t("needHelp")}}</span>
                    </div>
                </div>
                <div class="divider px-3"></div>
                <div id="chooseLang">
                    <span class="">{{$t('currentLang')}} <small id="langPopover"
                            class="changeLangButton">({{$t('changeLang')}})</small></span>
                </div>
                <b-popover :show.sync="showLangPopover" target="langPopover" placement="bottom" triggers="click focus">
                    <ul class="changeLangList">
                        <template v-for="(lang, key , index) in this.langs">
                            <li @click="changeLang(key)" :key=key+0>{{lang}}</li>
                            <b-dropdown-divider :key=key+1 v-if="index !== Object.keys(langs).length - 1">
                            </b-dropdown-divider>
                        </template>
                    </ul>
                </b-popover>
                <small class="version">{{$t('version')}} {{version}}</small>

            </div>
            <div class="account-button">
                <span class="icon-profil sidebar-elmt-icon"></span>
                <span>{{$t("accountButtonLabel")}}</span>
            </div>

        </div>
    </div>
</template>
<script>
    import {
        getProductsById
    } from "@/assets/js/Catalog";
    var Utils = require("@/assets/js/Utils");
    var basket = require('@/assets/js/Food/Basket');
    export default {
        props: {
            logoUrl: {
                type: String
            }
        },
        data() {
            let favoriteProduct = JSON.parse(localStorage.getItem("IEC_favoriteSelectedProductsId")) || []
            return {
                expandFav: false,
                version: "",
                expandHours: true,
                showLangPopover: false,
                favoriteProducts: JSON.parse(localStorage.getItem("IEC_favoriteProducts")) || [],
                selectedFavoriteProduct: favoriteProduct,
            }
        },
        mounted() {
            this.version = Utils.getConfig().pwaVersion;
        },
        methods: {
            changeLang(newLang) {
                if (!Object.keys(this.langs).includes(newLang))
                    newLang = "en";
                localStorage.setItem("IEC_lang", newLang)
                this.$root.$i18n.locale = newLang;
                this.lang = newLang;
                this.showLangPopover = false;
                window.location.reload()
                console.log(this.$i18n)
            },
            clearCache(){
                Utils.clearCache()
            },
            openLocker(lockerId){
                let lockerLocation = localStorage.getItem("IEC_lockerLocation") || "RTS23"
                let lockerInfo = {
                    lockerLocation: lockerLocation,
                    lockerID: lockerId
                }
                let mqttTopic = this.$mqtt.topics.LOCKER.format(lockerInfo)
                this.$mqtt.initMqttClient(()=> {
                    this.$mqtt.publishMqtt(mqttTopic.replace("#","open"), "", () => { console.log(`locker ${lockerId} opened`) });
                })
            },
            handleCheckBoxClick(favoriteProduct) {
                if (!this.selectedFavoriteProduct.includes(favoriteProduct.id)) {
                    this.selectedFavoriteProduct.push(favoriteProduct.id)
                    basket.addProductToLocalBasket(favoriteProduct);
                } else {
                    this.selectedFavoriteProduct = this.selectedFavoriteProduct.filter(element => element !=
                        favoriteProduct.id)
                    basket.decreaseProductFromLocalBasket(favoriteProduct);
                }
                this.$root.$emit("basketChgt")
                localStorage.setItem("IEC_favoriteSelectedProductsId", JSON.stringify(this.selectedFavoriteProduct))
            },
            changeRoute(route) {
                this.$router.push(route)
                this.$emit('hideSidebar')
            },
            productImg(favoriteProduct) {
                return `https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/catalog/product/image/${favoriteProduct.image}` //URL A CHANGER
            }
        },
    }
</script>
<style scoped>
    .sidebar-elmt-icon {
        width: 24px;
        height: 24px;
    }

    /* .checkbox{
        width: inherit;
        height: inherit;
        border: solid 0px;
        position: inherit;
    } */
    .custom-control-label {
        padding-top: 6px;
    }

    .sidebar-scroll {
        overflow: auto;
        height: 82%;
        box-shadow: inset -7px -5px 15px 0px #f3f2f2;

    }

    .participant {
        display: flex;
        width: 100%;
        margin: 8px 0;
        align-items: center;
    }

    .name-initials {
        padding: 4px;
        border: solid 1px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin-right: 8px;
    }

    .name-initials.active {
        background: rgb(var(--hot-food-color));
        color: white;
    }

    .basket-total {
        font-weight: bold;
        font-size: .65rem;
    }
    .shopName{
        text-align: center;
    }
    .shopName {
        margin-bottom: 0px;
    }
    .btnDisable{
        display: flex;
        align-items: center;
        flex-direction: column;
        color: rgb(153, 153, 153);
    }
    .version{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 12px;
    }
    .sidebar-header{
        display: flex;
        justify-content: space-between;
    }
    .btn-group{
        margin-bottom: 0px;
    }
</style>
