<template>
    <div class="modal-scanner-wrapper">
        <b-modal id="modalIdentification"
                 ref="modalIdentification"
                 centered
                 size="sm"
                 :title="$t('modalScannerTitle')"
                 header-text-variant="light"
                 footer-class="modal-footer-custom"
                 v-model="modalShow">

            <div v-if="modalShow" class="modal-scanner" @click="simulateScan('3088864620050')">
                <ScannerCamera inModal/>
                <ProductBloc class="in-modal-product-scan" v-if="scannedProduct" :key="productKey" :product="scannedProduct" showMore @showMore="clearTimeout(timers.productsTimer)"  @updateQty="handleUpdateQty"/>
                <div class="camera-explain">
                    <p :class="{'scan-explain': true, 'error': error}">{{explainScanValue}}</p>
                </div>
            </div>

            <template slot="modal-footer">
                <div class="p-2 d-flex justify-content-center align-items-stretch full-width">
                    <b-button class="mx-3 btn-active" @click="handleGoToBasket">{{$t("scannerModalGoToBasket")}}</b-button>
                    <b-button class="mx-3 btn-active" @click="handleContinue">{{$t("scannerModalContinueShopping")}}</b-button>
                </div>
            </template>

        </b-modal>
    </div>
</template>

<script>
    const Search = require("@/assets/js/SearchProduct");
    const Barcode = require("@/assets/js/Barcode");
    const Basket = require("@/assets/js/Food/Basket");
    import ProductBloc from "@/components/Food/ProductBloc";
    import ScannerCamera from "@/components/Food/ScannerCamera"
    export default {
        components: {
            ScannerCamera,
            ProductBloc
        },
        props: {
            show: {
                type: Boolean
            }
        },
        data(){
            return {
                scannedProduct: null,
                scannerInitialized: false,
                explainScanValue: this.$t('messageScanExplain'),
                productKey: this.generateKey(),
                timers: {
                    productsTimer: null,
                    errorTimer: null
                },
                error: false
            }
        },
        computed: {
            modalShow: {
                get(){
                    return this.show
                },
                set(value){
                    this.$emit("updateShow", value)
                }
            }
        },
        methods: {
            handleOk() {
                console.log("ok")
            },
            onDecode(decodedString){
                console.log(decodedString)
            
                Search.getProductByEan(decodedString.codeResult.code.split("_")[0], (product) => {
                    if (!product.error) {
                        clearTimeout(this.timers.productsTimer);
                        this.scannedProduct = product;

                        Basket.addProductToLocalBasket(product);

                        this.timers.productsTimer = setTimeout(()=> {
                            this.scannedProduct = null;
                        }, 6000)
                        this.productKey = this.generateKey()
                        
                        this.$root.$emit("basketChgt")
                    }else{
                        this.explainScanValue = this.$t("productNotFound")
                        this.error = true
                        this.timers.errorTimer = setTimeout(()=> {
                            this.error = false
                            this.explainScanValue = this.$t("messageScanExplain");
                        }, 3000)
                    }
                });
            },
            generateKey(){
                return 'scannedProduct-'+ parseInt(Math.random() *1000)
            },
            simulateScan(ean){
                let codeToScan = {
                    codeResult: {
                        code: ean
                    }
                }
                console.log(codeToScan)
                if(process.env.NODE_ENV != 'production'){
                    // this.onDecode(codeToScan)
                }
            },
            handleUpdateQty(){
                this.$root.$emit("basketChgt")
            },
            handleGoToBasket(){
                this.$emit("updateShow", false)
                this.$router.push({name: "Basket"})
            },
            handleContinue(){
                this.$emit("updateShow", false)
            }
        },
        watch: {
            modalShow(){
                if(this.modalShow){
                    Barcode.start(this.onDecode);
                    // this.scannerInitialized = true
                }else{
                    this.scannedProduct = null
                    Barcode.reset()
                }
            }
        }
    }
</script>

<style scoped>
    .modal-scanner{
        height: 50vh;
        position: relative;
        overflow: hidden;
    }
    .in-modal-product-scan{
        position: absolute;
        width: 100%;
        bottom: 8px;
    }
    .full-width{
        width: 100%;
    }
    .camera-explain{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .scan-explain.error{
        background: rgba(255, 10, 20, 0.5) ;
    }
</style>