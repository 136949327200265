import {CATALOG_URL} from "@/assets/js/constantUtils";

const axios = require('axios');

export function getAllParentCategories(callback) {
    axios.get(CATALOG_URL.GET_PARENT_CATEGORIES())
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}

export function getAllCategories(callback) {
    axios.get(CATALOG_URL.GET_ALL_CATEGORIES())
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}

export function getChildByParentCategory(parentId, callback) {

    axios.get(CATALOG_URL.GET_CATEGORIES_BY_PARENT_ID().format({parentId}))
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}

export function getCategoryById(categoryId, callback) {
    axios.get(CATALOG_URL.GET_CATEGORY_BY_ID().format({categoryId}))
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}

export function getProductsByCategory(categoryId) {
    return axios.get(CATALOG_URL.GET_PRODUCTS_BY_CATEGORY().format({categoryId}))
}

export function getProductsById(productId, callback) {
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var results = JSON.parse(request.response);
                callback(results);
            }
        }
    };
    request.open('GET', CATALOG_URL.GET_PRODCUT_BY_ID().format({productId}), false);
    request.send();
}

export function getSpecifications(productId, lang, callback){
    axios.get(CATALOG_URL.SPECIFICATIONS().format({productId, lang}))
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}
export function getCrossSellProducts(productId, callback){
    axios.get(CATALOG_URL.GET_CROSS_SELL().format({productId}))
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}
export function getNutriscore(productEan, callback){
    axios.get(CATALOG_URL.GET_NUTRISCORE().format({productEan}))
        .then(response => {
            if(response.status == 200){
                console.log(response.data);
            }
        })
}
