<template>
    <b-modal id="modalLockerLocation"
             ref="modalLockerLocation"
             centered
             no-close-on-backdrop
             no-close-on-esc
             hide-header-close
             header-text-variant="light"
             v-model="modalLockerLocationShow"
             :title="$t('lockerLocationModalTitle')">

        <p>{{$t('lockerLocationModalContent')}}</p>

        <b-form-input v-model="lockerLocation" ref="lockerLocationInput" :state="inputState" required></b-form-input>

        <div slot="modal-footer" class="modal-footer-custom" style="justify-content: end">
            <b-button class="float-left btn-danger" @click="$root.$emit('hideLockerLocationModal')">{{$t('cancel')}}</b-button>
            <b-button class="float-right ml-2" variant="success" @click="handleOk">{{$t('save')}}</b-button>
        </div>

    </b-modal>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"

    export default {
        name: "ModalLockerLocation",
        data () {
            return {
                modalLockerLocationShow: true,
                lockerLocation: localStorage.getItem("IEC_lockerLocation") || "RTS23",
                inputState: null
            }
        },
        methods: {
            handleOk(){
                if(isNotNull(this.lockerLocation)){
                    localStorage.setItem("IEC_lockerLocation", this.lockerLocation)
                    this.$root.$emit('hideLockerLocationModal')
                }else{
                    this.inputState = false
                }
            }
        }
    }
</script>
